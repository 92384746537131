import { ContactForm, ContentPhoto, FullPhoto, Header, Hero, Layout, Logo, Navigation, SmallPhoto, Team, TextBox, WhatWeDid, } from "components/CaseLayout";
import SEO from "components/seo";
import React from "react";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";
import { HEATING } from "shared/Types/cases";

const Heating = () => {
  const data = useCaseData(HEATING);
  return (
    <Layout color={data.color}>
      <SEO title={data.title} description={data.seo}/>
      <Hero data={data.hero}/>
      <Navigation type={HEATING}/>
      <WhatWeDid data={data.what_we_did}/>
      <Header data={data.headers.first}/>
      <Logo data={data.logo.first}/>
      <SmallPhoto data={data.small_photos.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.first}/>
      <ContentPhoto data={data.content_images.first}/>
      <TextBox background={data.color} color="#fff" data={data.text_boxes.second}/>
      <FullPhoto data={data.full_images.first}/>
      <ContentPhoto data={data.content_images.second}/>
      <Header data={data.headers.second}/>
      <ContentPhoto data={data.content_images.third}/>
      <TextBox data={data.text_boxes.third}/>
      <ContentPhoto data={data.content_images.fourth}/>
      <FullPhoto data={data.full_images.second}/>
      <ContentPhoto data={data.content_images.fifth}/>
      <Team data={data.team}/>
      <ContactForm/>
    </Layout>
  );
};

export default Heating;
